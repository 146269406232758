import { getCurrencySymbol } from "@/utils/misc";
import { ParseObject } from "./ParseTypes";

const renewReasons = new Map([
	[
		"auto-renews",
		"The subscription will auto renew at the current subscription level with the current duration",
	],
	[
		"auto-renews-at-quarterly",
		"The subscription will auto renew at the current subscription level and change the duration to quarterly",
	],
	[
		"auto-renews-at-yearly",
		"The subscription will auto renew at the current subscription level and change the duration to yearly",
	],
	[
		"auto-renews-with-mappass",
		"The subscription will auto renew and change the subscription level to map pass",
	],
	[
		"auto-renews-with-pro",
		"The subscription will auto renew and change the subscription level to pro",
	],
	[
		"no-renew-customer-cancled",
		"The subscription will not auto renew because the customer turned it off",
	],
	[
		"revoked-due-to-app-issue",
		"Apple has issued a refuned and revoked the subscription due to an actual or perceived issue within your app.",
	],
	["revoked", "Apple has issued a refuned and revoked the subscription"],
	[
		"expired-billing-issue-in-retry",
		"The subscription has expired due to a billing issue and Apple is still trying to get the user to update payment info",
	],
	[
		"expired-billing-issue",
		"The subscription has expired due to a billing issue and Apple is no longer trying to get the user to update payment info",
	],
	[
		"expired-customer-cancled",
		"The subscriptoin has expired because the customer canceld their subscription",
	],
	["expired-other", "The subscription expired for another reason"],
	[
		"no-renewal-info",
		"We have no information about the renewal and what will happen when it expires.",
	],
]);

export enum GPSubscriptionType {
	GPSubscriptionTypeFree = -1,

	// Map Pass subscriptions
	GPSubscriptionTypeFull1Months = 1,
	GPSubscriptionTypeFull2Months = 2,
	GPSubscriptionTypeFull3Months = 3,
	GPSubscriptionTypeFull6Months = 6,
	GPSubscriptionTypeFull12Months = 12,
	GPSubscriptionTypeFull36Months = 36,

	// PRO subscriptions
	GPSubscriptionTypePro1Months = 2001,
	GPSubscriptionTypePro2Months = 2002,
	GPSubscriptionTypePro3Months = 2003,
	GPSubscriptionTypePro6Months = 2006,
	GPSubscriptionTypePro12Months = 2012,
	GPSubscriptionTypePro36Months = 2036,

	// Upgrades from map pass to PRO
	GPSubscriptionTypeUpgradeToPro1Months = 3001,
	GPSubscriptionTypeUpgradeToPro2Months = 3002,
	GPSubscriptionTypeUpgradeToPro3Months = 3003,
	GPSubscriptionTypeUpgradeToPro6Months = 3006,
	GPSubscriptionTypeUpgradeToPro12Months = 3012,
	GPSubscriptionTypeUpgradeToProFromYearPass3Months = 4003,
	GPSubscriptionTypeUpgradeToProFromYearPass6Months = 4006,
	GPSubscriptionTypeUpgradeToProFromYearPass12Months = 4012,

	// iOS + Mac PRO subscriptions
	GPSubscriptionTypeProIOSMac3Months = 5003,
	GPSubscriptionTypeProIOSMac6Months = 5006,
	GPSubscriptionTypeProIOSMac12Months = 5012,
	// 5060 - 5 team members iOS + Mac PRO
	// 5070 - 10 team members iOS + Mac PRO

	// Mac Only PRO subscriptions
	GPSubscriptionTypeProMac3Months = 6003,
	GPSubscriptionTypeProMac6Months = 6006,
	GPSubscriptionTypeProMac12Months = 6012,
	// 6060 - 5 team members Mac PRO
	// 6070 - 10 team members Mac PRO

	// This will upgrade PRO on just one platform
	// to include all platforms.
	GPSubscriptionTypeUpgradePROForAllPlatforms = 7000,

	// Auto Renewing Map Pass Subscriptions
	GPSubscriptionTypeMapPassAuto3Months = 8003,
	GPSubscriptionTypeMapPassAuto12Months = 8012,

	// Auto Renewing Pro Subscriptions
	GPSubscriptionTypeProAuto3Months = 9003,
	GPSubscriptionTypeProAuto12Months = 9012,

	GPSubscriptionTypeProAuto12MonthsDiscount10 = 9101,
	GPSubscriptionTypeProAuto12MonthsDiscount20 = 9102,
	GPSubscriptionTypeProAuto12MonthsDiscount30 = 9103,
	GPSubscriptionTypeProAuto12MonthsDiscount40 = 9104,
	
	GPSubscriptionTypeProAuto12MonthsForever33 = 9133,

	// Lifetime memberships
	GPSubscriptionTypeLifetimeProIOS = 10101,
	GPSubscriptionTypeLifetimeProIOSMac = 10102,
	GPSubscriptionTypeLifetimeMapPassIOS = 10103,
	GPSubscriptionTypeLifetimeProMac = 10104,

	// Auto Renewing Hunt Subscriptions
	GPSubscriptionTypeHuntAuto1Months = 11001,
	GPSubscriptionTypeHuntAuto3Months = 11003,
	GPSubscriptionTypeHuntAuto12Months = 11012,

	GPSubscriptionTypeHuntAuto12MonthsDiscount10 = 11101,
	GPSubscriptionTypeHuntAuto12MonthsDiscount20 = 11102,
	GPSubscriptionTypeHuntAuto12MonthsDiscount30 = 11103,
	GPSubscriptionTypeHuntAuto12MonthsDiscount40 = 11104,
	
	GPSubscriptionTypeHuntAuto12MonthsForever33 = 11133,

	// Auto Renewing Team Subscriptions
	GPSubscriptionTypeTeamAuto1Months = 12001,
	GPSubscriptionTypeTeamAuto3Months = 12003,
	GPSubscriptionTypeTeamAuto12Months = 12012,

	GPSubscriptionTypeHunt1Months = 13001,
	GPSubscriptionTypeHunt3Months = 13003,
	GPSubscriptionTypeHunt12Months = 13012,
	GPSubscriptionTypeHunt36Months = 13036,


	// 10160 - 5 team members Lifetime iOS & Mac
	// 10170 - 5 team members Lifetime iOS & Mac

	// eslint-disable-next-line camelcase
	GPSubscriptionType_OLD_Base6Months = 1432,
	// eslint-disable-next-line camelcase
	GPSubscriptionType_OLD_Base12Months = 19652,
	// eslint-disable-next-line camelcase
	GPSubscriptionType_OLD_Full6Months = 20965,
	// eslint-disable-next-line camelcase
	GPSubscriptionType_OLD_Full12Months = 19876,
}

export function numberOfMonthsForType(type: number): number {
	let months = 0;
	if (
		type === GPSubscriptionType.GPSubscriptionTypeFull12Months ||
		type === GPSubscriptionType.GPSubscriptionTypeMapPassAuto12Months ||
		type === GPSubscriptionType.GPSubscriptionTypeProAuto12Months ||
		type === GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount10 ||
		type === GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount20 ||
		type === GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount30 ||
		type === GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount40 ||
		type === GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsForever33 ||
		type === GPSubscriptionType.GPSubscriptionTypePro12Months ||
		type === GPSubscriptionType.GPSubscriptionTypeTeamAuto12Months ||
		type === GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount10 ||
		type === GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount20 ||
		type === GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount30 ||
		type === GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount40 ||
		type === GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsForever33 ||
		type === GPSubscriptionType.GPSubscriptionTypeHuntAuto12Months ||
		type === GPSubscriptionType.GPSubscriptionTypeUpgradeToPro12Months ||
		type === GPSubscriptionType.GPSubscriptionTypeHunt12Months ||
		type === GPSubscriptionType.GPSubscriptionTypeUpgradeToProFromYearPass12Months ||
		type === GPSubscriptionType.GPSubscriptionTypeProMac12Months
	) {
		months = 12;
	} else if (
		type === GPSubscriptionType.GPSubscriptionTypeFull1Months ||
		type === GPSubscriptionType.GPSubscriptionTypeTeamAuto1Months ||
		type === GPSubscriptionType.GPSubscriptionTypeHuntAuto1Months ||
		type === GPSubscriptionType.GPSubscriptionTypePro1Months ||
		type === GPSubscriptionType.GPSubscriptionTypeHunt1Months ||
		type === GPSubscriptionType.GPSubscriptionTypeUpgradeToPro1Months
	) {
		months = 1;
	} else if (
		type === GPSubscriptionType.GPSubscriptionTypeFull2Months ||
		type === GPSubscriptionType.GPSubscriptionTypePro2Months ||
		type === GPSubscriptionType.GPSubscriptionTypeUpgradeToPro2Months
	) {
		months = 2;
	} else if (
		type === GPSubscriptionType.GPSubscriptionTypeFull3Months ||
		type === GPSubscriptionType.GPSubscriptionTypeProAuto3Months ||
		type === GPSubscriptionType.GPSubscriptionTypeTeamAuto3Months ||
		type === GPSubscriptionType.GPSubscriptionTypeHuntAuto3Months ||
		type === GPSubscriptionType.GPSubscriptionTypeMapPassAuto3Months ||
		type === GPSubscriptionType.GPSubscriptionTypePro3Months ||
		type === GPSubscriptionType.GPSubscriptionTypeHunt3Months ||
		type === GPSubscriptionType.GPSubscriptionTypeUpgradeToPro3Months ||
		type === GPSubscriptionType.GPSubscriptionTypeUpgradeToProFromYearPass3Months ||
		type === GPSubscriptionType.GPSubscriptionTypeProMac3Months
	) {
		months = 3;
	} else if (
		type === GPSubscriptionType.GPSubscriptionTypeFull6Months ||
		type === GPSubscriptionType.GPSubscriptionTypePro6Months ||
		type === GPSubscriptionType.GPSubscriptionTypeUpgradeToPro6Months ||
		type === GPSubscriptionType.GPSubscriptionTypeUpgradeToProFromYearPass6Months ||
		type === GPSubscriptionType.GPSubscriptionTypeProMac6Months
	) {
		months = 6;
	} else if (
		type === GPSubscriptionType.GPSubscriptionTypePro36Months ||
		type === GPSubscriptionType.GPSubscriptionTypeHunt36Months ||
		type === GPSubscriptionType.GPSubscriptionTypeFull36Months) {
			return 36;
	} else if (
		type === GPSubscriptionType.GPSubscriptionTypeLifetimeProIOS ||
		type === GPSubscriptionType.GPSubscriptionTypeLifetimeProIOSMac ||
		type === GPSubscriptionType.GPSubscriptionTypeLifetimeProMac ||
		type === GPSubscriptionType.GPSubscriptionTypeLifetimeMapPassIOS
	) {
		// 20 years - Just to have something for the database
		// The active query also queries for type
		months = 12 * 20;
	}

	return months;
}

export function subscriptionTypesList(): string[] {
	const types = [];
	for (const subType in GPSubscriptionType) {
		if (!isNaN(Number(subType))) {
			const type = GPSubscriptionType[subType];
			types.push(type);
		}
	}
	return types;
}

export function subscriptionTypesList2(): {
	name: string;
	type: GPSubscriptionType;
}[] {
	const types: { name: string; type: GPSubscriptionType }[] = [];
	for (const subType in GPSubscriptionType) {
		if (!isNaN(Number(subType))) {
			const type = GPSubscriptionType[subType];
			const typeName = subTypeName(type);
			types.push({
				name: typeName,
				type: type as unknown as GPSubscriptionType,
			});
		}
	}
	return types;
}

export function usedSubscriptionTypesList(): {
	name: string;
	type: GPSubscriptionType;
}[] {
	return [
		{
			name: "Topo Maps+ Pro",
			type: GPSubscriptionType.GPSubscriptionTypeProAuto12Months,
		},
		{
			name: "Topo Maps+ Hunt",
			type: GPSubscriptionType.GPSubscriptionTypeHuntAuto12Months,
		},
		{
			name: "Topo Maps+ Team",
			type: GPSubscriptionType.GPSubscriptionTypeTeamAuto12Months,
		},
	];
}

export function usedSubscriptionTypesList2(): Map<string, GPSubscriptionType> {
	const types = new Map<string, GPSubscriptionType>();
	types.set(
		"Topo Maps+ Pro",
		GPSubscriptionType.GPSubscriptionTypeProAuto12Months
	);
	types.set(
		"Topo Maps+ Hunt",
		GPSubscriptionType.GPSubscriptionTypeHuntAuto12Months
	);
	types.set(
		"Topo Maps+ Team",
		GPSubscriptionType.GPSubscriptionTypeTeamAuto12Months
	);
	types.set(
		"Map Pass",
		GPSubscriptionType.GPSubscriptionTypeMapPassAuto12Months
	);
	return types;
}

export function subTypeName(type: number | string | GPSubscriptionType): string {
	if (type == GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount10) {
		return "Topo Maps+ Pro (10% off first year)";
	} else if (type == GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount20) {
		return "Topo Maps+ Pro (20% off first year)";
	} else if (type == GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount30) {
		return "Topo Maps+ Pro (30% off first year)";
	} else if (type == GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount40) {
		return "Topo Maps+ Pro (40% off first year)";
	} else if (type == GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsForever33) {
		return "Topo Maps+ Pro (33% off forever)";
	} else if (type == GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount10) {
		return "Topo Maps+ Elite (10% off first year)";
	} else if (type == GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount20) {
		return "Topo Maps+ Elite (20% off first year)";
	} else if (type == GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount30) {
		return "Topo Maps+ Elite (30% off first year)";
	} else if (type == GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount40) {
		return "Topo Maps+ Elite (40% off first year)";
	} else if (type == GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsForever33) {
		return "Topo Maps+ Elite (25% off forever)";
	}
	const level = subLevel(type);
	if (level == "pro") {		
		return "Topo Maps+ Pro";
	} else if (level == "hunt") {
		return "Topo Maps+ Elite";
	} else if (level == "team") {
		return "Topo Maps+ Team";
	} else if (level == "mapPass") {
		return "Essentials";
	} else if (level == "free") {
		return "Topo Maps+ Free";
	} else if (level == "unknown") {
		return `Unknown subscription type - ${type}`;
	}
	return `Unknown subscription type - ${type}`;
}

export function subLevelInt(type: number | string | GPSubscriptionType): number {
	const level = subLevel(type);
	if (level == "free" || level == "uknown") {
		return 0;
	} else if (level == "mapPass") {
		return 1;
	} else if (level == "pro") {
		return 2;
	} else if (level == "hunt") {
		return 3;
	} else if (level == "team") {
		return 4;
	}
	return 0;
}

export function subLevel(type: number | string | GPSubscriptionType): string {
	// const subType = GPSubscriptionType[this.type];
	switch (type) {
		case GPSubscriptionType.GPSubscriptionTypePro1Months:
		case GPSubscriptionType.GPSubscriptionTypePro2Months:
		case GPSubscriptionType.GPSubscriptionTypePro3Months:
		case GPSubscriptionType.GPSubscriptionTypePro6Months:
		case GPSubscriptionType.GPSubscriptionTypePro12Months:
		case GPSubscriptionType.GPSubscriptionTypePro36Months:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12Months:
		case GPSubscriptionType.GPSubscriptionTypeProAuto3Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro1Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro2Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro3Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro6Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro12Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToProFromYearPass3Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToProFromYearPass6Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToProFromYearPass12Months:
		case GPSubscriptionType.GPSubscriptionTypeLifetimeProIOS:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount10:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount20:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount30:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount40:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsForever33:


			return "pro";

		case GPSubscriptionType.GPSubscriptionTypeProIOSMac3Months:
		case GPSubscriptionType.GPSubscriptionTypeProIOSMac6Months:
		case GPSubscriptionType.GPSubscriptionTypeProIOSMac12Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradePROForAllPlatforms:
		case GPSubscriptionType.GPSubscriptionTypeLifetimeProIOSMac:
			return "pro";

		case GPSubscriptionType.GPSubscriptionTypeProMac3Months:
		case GPSubscriptionType.GPSubscriptionTypeProMac6Months:
		case GPSubscriptionType.GPSubscriptionTypeProMac12Months:
		case GPSubscriptionType.GPSubscriptionTypeLifetimeProMac:
			return "pro";

		case GPSubscriptionType.GPSubscriptionTypeFull1Months:
		case GPSubscriptionType.GPSubscriptionTypeFull2Months:
		case GPSubscriptionType.GPSubscriptionTypeFull3Months:
		case GPSubscriptionType.GPSubscriptionTypeFull6Months:
		case GPSubscriptionType.GPSubscriptionTypeFull12Months:
		case GPSubscriptionType.GPSubscriptionTypeFull36Months:
		case GPSubscriptionType.GPSubscriptionTypeLifetimeMapPassIOS:
		case GPSubscriptionType.GPSubscriptionTypeMapPassAuto3Months:
		case GPSubscriptionType.GPSubscriptionTypeMapPassAuto12Months:
		case GPSubscriptionType.GPSubscriptionType_OLD_Base6Months:
		case GPSubscriptionType.GPSubscriptionType_OLD_Base12Months:
		case GPSubscriptionType.GPSubscriptionType_OLD_Full6Months:
		case GPSubscriptionType.GPSubscriptionType_OLD_Full12Months:
			return "mapPass";

		case GPSubscriptionType.GPSubscriptionTypeFree:
			return "tree";

		case GPSubscriptionType.GPSubscriptionTypeHuntAuto1Months:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto3Months:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12Months:
		case GPSubscriptionType.GPSubscriptionTypeHunt12Months:
		case GPSubscriptionType.GPSubscriptionTypeHunt3Months:
		case GPSubscriptionType.GPSubscriptionTypeHunt1Months:
		case GPSubscriptionType.GPSubscriptionTypeHunt36Months:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount10:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount20:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount30:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount40:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsForever33:		
			return "hunt";

		case GPSubscriptionType.GPSubscriptionTypeTeamAuto1Months:
		case GPSubscriptionType.GPSubscriptionTypeTeamAuto3Months:
		case GPSubscriptionType.GPSubscriptionTypeTeamAuto12Months:
			return "team";
		default:
			return "unknown";
	}
}

export function isAutoRenewingSubscription(type: number | string | GPSubscriptionType): boolean {
	switch (type) {
		case GPSubscriptionType.GPSubscriptionTypePro1Months:
		case GPSubscriptionType.GPSubscriptionTypePro2Months:
		case GPSubscriptionType.GPSubscriptionTypePro3Months:
		case GPSubscriptionType.GPSubscriptionTypePro6Months:
		case GPSubscriptionType.GPSubscriptionTypePro12Months:
		case GPSubscriptionType.GPSubscriptionTypePro36Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro1Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro2Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro3Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro6Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToPro12Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToProFromYearPass3Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToProFromYearPass6Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradeToProFromYearPass12Months:
		case GPSubscriptionType.GPSubscriptionTypeLifetimeProIOS:
		case GPSubscriptionType.GPSubscriptionTypeProIOSMac3Months:
		case GPSubscriptionType.GPSubscriptionTypeProIOSMac6Months:
		case GPSubscriptionType.GPSubscriptionTypeProIOSMac12Months:
		case GPSubscriptionType.GPSubscriptionTypeUpgradePROForAllPlatforms:
		case GPSubscriptionType.GPSubscriptionTypeLifetimeProIOSMac:
				return false;

		case GPSubscriptionType.GPSubscriptionTypeProAuto12Months:
		case GPSubscriptionType.GPSubscriptionTypeProAuto3Months:
		case GPSubscriptionType.GPSubscriptionTypeMapPassAuto3Months:
		case GPSubscriptionType.GPSubscriptionTypeMapPassAuto12Months:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto1Months:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto3Months:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12Months:
		case GPSubscriptionType.GPSubscriptionTypeTeamAuto1Months:
		case GPSubscriptionType.GPSubscriptionTypeTeamAuto3Months:
		case GPSubscriptionType.GPSubscriptionTypeTeamAuto12Months:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount10:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount20:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount30:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsDiscount40:
		case GPSubscriptionType.GPSubscriptionTypeProAuto12MonthsForever33:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount10:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount20:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount30:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsDiscount40:
		case GPSubscriptionType.GPSubscriptionTypeHuntAuto12MonthsForever33:			
			return true;			

		case GPSubscriptionType.GPSubscriptionTypeProMac3Months:
		case GPSubscriptionType.GPSubscriptionTypeProMac6Months:
		case GPSubscriptionType.GPSubscriptionTypeProMac12Months:
		case GPSubscriptionType.GPSubscriptionTypeLifetimeProMac:
			return false;

		case GPSubscriptionType.GPSubscriptionTypeFull1Months:
		case GPSubscriptionType.GPSubscriptionTypeFull2Months:
		case GPSubscriptionType.GPSubscriptionTypeFull3Months:
		case GPSubscriptionType.GPSubscriptionTypeFull6Months:
		case GPSubscriptionType.GPSubscriptionTypeFull12Months:
		case GPSubscriptionType.GPSubscriptionTypeFull36Months:
		case GPSubscriptionType.GPSubscriptionTypeLifetimeMapPassIOS:
		case GPSubscriptionType.GPSubscriptionType_OLD_Base6Months:
		case GPSubscriptionType.GPSubscriptionType_OLD_Base12Months:
		case GPSubscriptionType.GPSubscriptionType_OLD_Full6Months:
		case GPSubscriptionType.GPSubscriptionType_OLD_Full12Months:
		case GPSubscriptionType.GPSubscriptionTypeFree:
		case GPSubscriptionType.GPSubscriptionTypeHunt12Months:
		case GPSubscriptionType.GPSubscriptionTypeHunt3Months:
		case GPSubscriptionType.GPSubscriptionTypeHunt1Months:
		case GPSubscriptionType.GPSubscriptionTypeHunt36Months:
			return false;
		default:
			return false;
	}}

export function formattedPrice(price: number, currency: string): string {
	if (price === 0) {
		return "Free";
	} else {
		const currencySymbol = getCurrencySymbol(currency);
		return `${currencySymbol}${price}`;
	}
}

// export function stripePriceForProduct(productId: string): (string | null) {
// 	if (productId == "9012") {
// 		return "price_1L5zGHL2X8wDuDl6AhLKuCy4";
// 	} else if (productId == "9003") {
// 		return "price_1L9FJQL2X8wDuDl6PSFAl0fw";
// 	} else if (productId == "2012") {		
// 		return "price_1L5zGHL2X8wDuDl6DXJE7ztQ";
// 	} else if (productId == "2036") {
// 		return "price_1LBQJiL2X8wDuDl6lTEUEmTU";
// 	} else if (productId == "2003") {
// 		return "price_1L9FKtL2X8wDuDl6qoxpvoNp";
// 	} else if (productId == "11012") {
// 		return "price_1L9EuzL2X8wDuDl6P3i3dSnw";
// 	} else if (productId == "11003") {
// 		return "price_1L9FN0L2X8wDuDl6rS2pQxlp";
// 	} else if (productId == "13012") {
// 		return "price_1L9EuzL2X8wDuDl6pAvn3Nd0";
// 	} else if (productId == "13036") {
// 		return "price_1LBQM5L2X8wDuDl6o2MQZEeT";
// 	} else if (productId == "13003") {
// 		return "price_1L9FNHL2X8wDuDl6QYljdeC4";
// 	}
// 	return null;
// }

export default class GPSubscription {
	id: string;
	appStoreAttributionCampaign: string;
	appStoreAttributionDetails: string;
	appStoreAttributionKeyword: string;
	autoRenewStatus: string;
	lastRecieptData: string;
	originalTransactionId: string;
	priceCurrency: string;
	source: string;
	subscriptionOriginSource: string;
	uuid: string;
	userId: string;
	webOrderLineId: string;

	renewalEmail: boolean;
	theft?: boolean;
	trial: boolean;
	fromSharing: boolean;

	type: number;
	price: number;
	syncTimestamp: number;

	autoRenewOriginalPurchaseDate: Date;
	createdAt: Date;
	expiration: Date;
	purchaseDate: Date;
	updatedAt: Date;

	constructor(data: {
		id: string;
		renewalEmail: boolean;
		userId: string;
		purchaseDate: Date;
		expiration: Date;
		updatedAt: Date;
		trial: boolean;
		autoRenewStatus: string;
		type: number;
		price: number;
		autoRenewOriginalPurchaseDate: Date;
		appStoreAttributionDetails: string;
		appStoreAttributionKeyword: string;
		originalTransactionId: string;
		priceCurrency: string;
		subscriptionOriginSource: string;
		webOrderLineId: string;
		fromSharing: boolean;
		theft: boolean;
		uuid: string;
		lastRecieptData: string;
		syncTimestamp: number;
		createdAt: Date;
		appStoreAttributionCampaign: string;
		source: string;
	}) {
		this.id = data.id;
		this.renewalEmail = data.renewalEmail;
		this.userId = data.userId;
		this.purchaseDate = data.purchaseDate;
		this.expiration = data.expiration;
		this.updatedAt = data.updatedAt;
		this.trial = data.trial;
		this.autoRenewStatus = data.autoRenewStatus;
		this.type = data.type;
		this.price = data.price;
		this.autoRenewOriginalPurchaseDate = data.autoRenewOriginalPurchaseDate;
		this.appStoreAttributionDetails = data.appStoreAttributionDetails;
		this.appStoreAttributionKeyword = data.appStoreAttributionKeyword;
		this.originalTransactionId = data.originalTransactionId;
		this.priceCurrency = data.priceCurrency;
		this.subscriptionOriginSource = data.subscriptionOriginSource;
		this.webOrderLineId = data.webOrderLineId;
		this.fromSharing = data.fromSharing;
		this.theft = data.theft;
		this.uuid = data.uuid;
		this.lastRecieptData = data.lastRecieptData;
		this.syncTimestamp = data.syncTimestamp;
		this.createdAt = data.createdAt;
		this.appStoreAttributionCampaign = data.appStoreAttributionCampaign;
		this.source = data.source;
	}

	get numberOfMonthsForType(): number {
		return numberOfMonthsForType(this.type);
	}

	get subTypeName(): string {
		return subTypeName(this.type);
	}

	get formattedPrice(): string {
		return formattedPrice(this.price, this.priceCurrency);
	}

	get renewStatusReason(): string {
		const reason = renewReasons.get(this.autoRenewStatus);
		if (reason) {
			return reason;
		}
		return "No Data";
	}

	static fromParseUser(object: ParseObject): GPSubscription {
		const id = object.id;
		const renewalEmail = object.get("renewalEmail");
		const purchaseDate = object.get("purchaseDate");
		const expiration = object.get("expiration");
		const updatedAt = object.get("updatedAt");
		const userId = object.get("user").id;
		const trial = object.get("trial");
		const autoRenewStatus = object.get("autoRenewStatus");
		const type = object.get("type");
		const price = object.get("price");
		const autoRenewOriginalPurchaseDate = object.get(
			"autoRenewOriginalPurchaseDate"
		);
		const appStoreAttributionDetails = object.get("appStoreAttributionDetails");
		const appStoreAttributionKeyword = object.get("appStoreAttributionKeyword");
		const originalTransactionId = object.get("originalTransactionId");
		const priceCurrency = object.get("price_currency");
		const subscriptionOriginSource = object.get("subscriptionOriginSource");
		const webOrderLineId = object.get("webOrderLineId");
		const fromSharing = object.get("fromSharing");
		const theft = object.get("theft");
		const uuid = object.get("uuid");
		const lastRecieptData = object.get("lastRecieptData");
		const syncTimestamp = object.get("syncTimestamp");
		const createdAt = object.get("createdAt");
		const appStoreAttributionCampaign = object.get(
			"appStoreAttributionCampaign"
		);
		const source = object.get("source");

		return new GPSubscription({
			id: id,
			renewalEmail: renewalEmail,
			userId: userId,
			purchaseDate: purchaseDate,
			expiration: expiration,
			updatedAt: updatedAt,
			trial: trial,
			autoRenewStatus: autoRenewStatus,
			type: type,
			price: price,
			autoRenewOriginalPurchaseDate: autoRenewOriginalPurchaseDate,
			appStoreAttributionDetails: appStoreAttributionDetails,
			appStoreAttributionKeyword: appStoreAttributionKeyword,
			originalTransactionId: originalTransactionId,
			priceCurrency: priceCurrency,
			subscriptionOriginSource: subscriptionOriginSource,
			webOrderLineId: webOrderLineId,
			fromSharing: fromSharing,
			theft: theft,
			uuid: uuid,
			lastRecieptData: lastRecieptData,
			syncTimestamp: syncTimestamp,
			createdAt: createdAt,
			appStoreAttributionCampaign: appStoreAttributionCampaign,
			source: source,
		});
	}
}
